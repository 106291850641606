<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
import echarts from 'echarts'

require('echarts/theme/macarons')
import {debounce} from '@/utils'

export default {
  name: "Follow",
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null,
      queryForm: {
        startTime: '',
        endTime: ''
      },
    }
  },
  mounted() {
    this.chart = echarts.init(this.$el, 'macarons')
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize()
      }
    }, 100)
    window.addEventListener('resize', this.__resizeHandler)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    refreshCart(queryForm) {
      this.queryForm = queryForm
      this.initChart()
    },
    initChart() {

      this.$http({
        url: this.$http.adornUrl("/admin/report/follow"),
        method: "POST",
        data: this.queryForm
      }).then(({data}) => {
        if (data && data.code === 0) {

          const userFollowUser = data.result.userFollowUser
          const userFollowVirtual = data.result.userFollowVirtual
          const followTotal = data.result.followTotal
          const day = []
          const userFollowUserTotal = []
          const userFollowUserAvg = []

          const userFollowVirtualTotal = []
          const userFollowVirtualAvg = []

          const followTotalTotal = []
          const followTotalAvg = []

          userFollowUser.forEach(function (item) {
            day.push(item.statDate)
            userFollowUserTotal.push(item.total)
            userFollowUserAvg.push(item.avg)
          })
          userFollowVirtual.forEach(function (item) {
            userFollowVirtualTotal.push(item.total)
            userFollowVirtualAvg.push(item.avg)
          })
          followTotal.forEach(function (item) {
            followTotalTotal.push(item.total)
            followTotalAvg.push(item.avg)
          })
          this.chart.setOption({
            title: {
              text: '关注数'
            },
            tooltip: {
              trigger: 'axis'
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            legend: {
              data: ['总关注数', '人均关注数', '总用户关注用户数', '人均用户关注用户数', '总用户关注虚拟人数', '人均用户关注虚拟人数']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [{
              type: 'category',
              data: day,
              boundaryGap: false
            }],
            yAxis: [{
              type: 'value'
            }],
            series: [
              {
                name: '总关注数',
                type: 'line',
                data: followTotalTotal
              },
              {
                name: '人均关注数',
                type: 'line',
                data: followTotalAvg
              },
              {
                name: '总用户关注用户数',
                type: 'line',
                data: userFollowUserTotal
              },
              {
                name: '人均用户关注用户数',
                type: 'line',
                data: userFollowUserAvg
              },
              {
                name: '总用户关注虚拟人数',
                type: 'line',
                data: userFollowVirtualTotal
              },
              {
                name: '人均用户关注虚拟人数',
                type: 'line',
                data: userFollowVirtualAvg
              }
            ]
          })
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
        this.$message.error(err);
      })


    }
  }
}
</script>
